// Variables

// https://github.com/bchiang7/v4#-color-reference
// Main color
$primary-color: #0a192f;

// Anchor color
$secondary-color: #64ffda;

// Font color
$font-color: #a8b2d1;

$navbar-text-shadow: true;
$header-text-shadow: true;
$header-background-color: $primary-color;
$intro-text-color: #64ffda;
$navbar-color: #64ffda;
$navbar-lg-collapse-background-color: $header-background-color;
$navbar-lg-background: transparent;
$navbar-sm-background-color: $navbar-lg-collapse-background-color;

$social-buttons-color: $secondary-color;
